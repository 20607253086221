import { SignIn } from "@clerk/nextjs"
import { Box, Center, Flex, Loader } from "@mantine/core"
import { PAGE_BACKGROUND_COLOR } from "@soar/frontend/components"
import { useSelections, useUser } from "@soar/frontend/contexts"
import { Profile } from "@soar/shared/types"
import { useRouter } from "next/router"
import { ReactNode, useEffect } from "react"

function LoadingIndicator() {
  return (
    <Center mt="xl">
      <Loader size="xl" variant="bars" />
    </Center>
  )
}

function ProtectedPage({
  children,
  profileType,
  organizationId,
}: {
  children: ReactNode
  profileType?: Profile["type"]
  organizationId?: string
}) {
  const { isAuthenticated, status, isLoaded, isSignedInToAuthService } = useUser()
  const router = useRouter()
  const path = router.asPath
  const redirectUrl = `/auth?relayState=${encodeURIComponent(encodeURIComponent(path))}`
  const { ensureSelected } = useSelections()
  // const { loginWithRedirect } = useAuth0()
  //
  useEffect(() => {
    if (profileType != null) {
      ensureSelected(profileType)
    }
  }, [profileType, isAuthenticated])

  if (isLoaded) {
    if (isSignedInToAuthService) {
      //Passed clerk, now return based on db lookup
      if (status === "loading" || status === "initial") {
        // Grabbing the DB User
        return <LoadingIndicator />
      } else if (status === "not_found") {
        // new user, redirect to auth page
        router.push(redirectUrl)
        return <></>
      } else if (status === "error") {
        // unexpected error
        return <>Something went wrong while authenticating...</>
      }
    } else {
      //auth service has loaded but user is not authenticated
      router.push(`/login?redirectUrl=${redirectUrl}`)
      /*
      return (
        <Flex direction="column" align="center" h="100%">
          <SignInBox redirectUrl={redirectUrl} />
        </Flex>
      )
      */
    }
  } else {
    // Auth service is loading
    return <LoadingIndicator />
  }

  if (isAuthenticated) {
    return <>{children} </>
  }

  //Shouldn't get down here but if we do, something janky happened
  return <>Something went wrong while authenticating...</>
}

export default ProtectedPage
